import ReactDOM from 'react-dom/client';
import Phone from './Phone';
import { Button } from 'antd';
import { useEffect } from 'react';

const returnToMainWindow = () => {
  const root = ReactDOM.createRoot(document.getElementById('phoneContainer'));
  root.render(<Phone />);
};
const EmptyApp = (props) => {
  useEffect(() => {}, []);
  return (
    <div className={'empty-app-cls'}>
      <div style={{ fontSize: '2rem' }}>{props.appId}</div>
      <Button
        type='primary'
        onClick={returnToMainWindow}
        style={{ top: '45%' }}>
        return
      </Button>
    </div>
  );
};
export default EmptyApp;
