import DeckApp from './DeckApp';
import TodoApp from './TodoApp';
import ChatApp from './ChatApp';
import GenImageApp from './GenImageApp';

const Phone = (props) => {
  return (
    <div id='phoneMainWindow' className={'phone-deck-cls'}>
      <div id={'deckAppGrid'} className={'phone-deck-app-grid-cls'}>
        <DeckApp appName={'测试'} appId={'testApp'} />
        <DeckApp appName={'测试一个很长很长很长的名字'} appId={'testApp1'} />
        <DeckApp appName={'myubbberllojksdsdsdsdsddsd'} appId={'testApp2'} />
        <DeckApp appName={'TodoApp'} appId={'TodoApp'} app={<TodoApp />} />
        <DeckApp appName={'ChatLLAMA'} appId={'ChatLLAMA'} app={<ChatApp />} />
        <DeckApp
          appName={'GenImage'}
          appId={'GenImage'}
          app={<GenImageApp />}
        />
        <DeckApp
          appName={'test with a very long long name'}
          appId={'testApp6'}
        />
      </div>
    </div>
  );
};
export default Phone;
