import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Phone from './components/Phone';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div >
      <App />
      <div id={'phoneContainer'} className={'phone-cls'}>
        <Phone />
      </div>
    </div>
  </React.StrictMode>,
);
