import ReactDOM from 'react-dom/client';
import EmptyApp from './EmptyApp';

const AppIcon = (props) => {
  return <div className={'deck-app-icon-cls'}></div>;
};
const AppName = (props) => {
  return <div className={'deck-app-name-cls'}>{props.appName}</div>;
};

const DeckApp = (props) => {
  const openDeckApp = () => {
    const root = ReactDOM.createRoot(document.getElementById('phoneContainer'));
    if (props.app) {
      root.render(props.app);
    } else {
      root.render(<EmptyApp appId={props.appId} />);
    }
  };

  return (
    <div className={'deck-app-cls'} onClick={openDeckApp}>
      <AppIcon />
      <AppName appName={props.appName} />
    </div>
  );
};
export default DeckApp;
