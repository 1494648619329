import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Input, Space } from 'antd';
import ReactDOM from 'react-dom/client';
import Phone from './Phone';

const ChatApp = (props) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [chatRecord, setChatRecords] = useState([]);
  const recordRef = useRef([]);
  useEffect(() => {}, []);
  const chatWithAI = (message) => {
    axios
      .post('/api/chat', {
        prompt: message,
      })
      .then((response) => {
        const currList = JSON.parse(JSON.stringify(recordRef.current));
        currList.push([1, response.data.response]);
        recordRef.current = currList;
        setChatRecords(currList);
      });
  };
  const onChange = (event) => {
    setInputValue(event.target.value);
  };
  const chatMessage = () => {
    const value = inputRef.current.input.value;
    if (value === null || value === '') {
      return;
    }
    const currList = JSON.parse(JSON.stringify(recordRef.current));
    currList.push([0, value]);
    setChatRecords(currList);
    recordRef.current = currList;
    chatWithAI(value);
  };
  const returnToMainWindow = () => {
    const root = ReactDOM.createRoot(document.getElementById('phoneContainer'));
    root.render(<Phone />);
  };
  return (
    <div className={'chat-app'}>
      <div className={'chat-app-title'}>Chat</div>
      <Button
        type='primary'
        onClick={returnToMainWindow}
        style={{
          position: 'absolute',
          right: '20px',
          top: '22px',
        }}>
        返回
      </Button>
      <Space.Compact
        style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
        <Input
          ref={inputRef}
          placeholder='another input'
          value={inputValue}
          onChange={onChange}
        />
        <Button type='primary' onClick={chatMessage}>
          Chat
        </Button>
      </Space.Compact>
      <div className={'record-list'}>
        {chatRecord.map((record) => {
          return (
            <div
              style={
                record[0] === 0
                  ? { textAlign: 'right', color: '#3bb16c' }
                  : null
              }>
              {record[1]}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ChatApp;
