import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Input, Space } from 'antd';
import ReactDOM from 'react-dom/client';
import Phone from './Phone';

const GenImageApp = (props) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {}, []);
  const chatWithAI = (message) => {
    axios
      .post(
        '/api/image',
        {
          prompt: message,
        },
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const objectURL = window.URL.createObjectURL(response.data);
        console.log(objectURL);
        setImageUrl(objectURL);
        setTimeout(() => {
          window.URL.revokeObjectURL(objectURL);
        }, 500);
      });
  };
  const onChange = (event) => {
    setInputValue(event.target.value);
  };
  const chatMessage = () => {
    const value = inputRef.current.input.value;
    if (value === null || value === '') {
      return;
    }
    chatWithAI(value);
  };
  const returnToMainWindow = () => {
    const root = ReactDOM.createRoot(document.getElementById('phoneContainer'));
    root.render(<Phone />);
  };
  return (
    <div className={'chat-app'}>
      <div className={'chat-app-title'}>Chat</div>
      <Button
        type='primary'
        onClick={returnToMainWindow}
        style={{
          position: 'absolute',
          right: '20px',
          top: '22px',
        }}>
        返回
      </Button>
      <Space.Compact
        style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
        <Input
          ref={inputRef}
          placeholder='another input'
          value={inputValue}
          onChange={onChange}
        />
        <Button type='primary' onClick={chatMessage}>
          Chat
        </Button>
      </Space.Compact>
      <img
        src={imageUrl}
        alt={''}
        style={{
          width: '24rem',
          height: '24rem',
          padding: 'calc(50% - 12rem)',
        }}
      />
    </div>
  );
};
export default GenImageApp;
