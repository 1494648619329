import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Space } from 'antd';
import ReactDOM from 'react-dom/client';
import Phone from './Phone';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const TodoApp = (props) => {
  const [todoList, setTodoList] = useState([]);
  const [maxKey, setMaxKey] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const returnToMainWindow = () => {
    const root = ReactDOM.createRoot(document.getElementById('phoneContainer'));
    root.render(<Phone />);
  };
  const inputRef = useRef(null);

  const sortTodoList = (todos) => {
    todos.sort((a, b) => {
      if (a.status !== b.status) {
        return a.status - b.status;
      }
      if (a.status === 1) {
        return b.finishTime - a.finishTime;
      }
      return b.createTime - a.createTime;
    });
  };
  const queryTodo = useCallback(() => {
    axios
      .get('/api/todolist', {
        params: { user: 'user123' },
      })
      .then((response) => {
        const currList = response.data;
        let maxIndex = 0;
        (currList || []).forEach((item) => {
          maxIndex = Math.max(item.key, maxIndex);
        });
        setMaxKey(maxIndex);
        sortTodoList(currList);
        setTodoList(currList);
      });
  }, []);

  useEffect(() => {
    queryTodo();
    setShowAdd(false);
  }, [queryTodo]);

  const handleItemClick = (isFinish, key) => {
    if (isFinish) {
      return;
    }
    const currTodoList = JSON.parse(JSON.stringify(todoList));
    currTodoList.forEach((item) => {
      if (item.key === key) {
        item.status = 1;
        item.finishTime = new Date().getTime();
      }
    });
    sortTodoList(currTodoList);
    setTodoList(currTodoList);
    saveTodo(currTodoList);
  };

  const saveTodo = (saveTodoList) => {
    axios
      .post('/api/todolist', {
        user: 'user123',
        todoList: saveTodoList,
      })
      .then(() => {
        queryTodo();
      });
  };

  const addTodo = () => {
    const value = inputRef.current.input.value;
    if (value === null || value === '') {
      return;
    }
    const newKey = maxKey + 1;
    setMaxKey(newKey);
    const newItem = {
      key: newKey,
      value: inputRef.current.input.value,
      status: 0,
      createTime: new Date().getTime(),
      finishTime: -1,
    };
    const currTodoList = JSON.parse(JSON.stringify(todoList));
    currTodoList.push(newItem);
    sortTodoList(currTodoList);
    setTodoList(currTodoList);
    saveTodo(currTodoList);
    setShowAdd(false);
    setInputValue('');
  };
  const clickAdd = () => {
    setShowAdd(true);
    setTimeout(() => {
      inputRef.current.focus({ cursor: 'start' });
    }, 200);
  };
  const onChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <div className={'todo-app'}>
      <div className={'todo-app-title'}>TodoList</div>
      <Button
        type='primary'
        onClick={returnToMainWindow}
        style={{
          position: 'absolute',
          right: '20px',
          top: '22px',
        }}>
        返回
      </Button>
      <div className={'todo-app-items'}>
        {todoList.map((todoItem) => {
          const isFinish = todoItem.status === 1;
          const className = isFinish
            ? 'todo-app-todo-item finish-item'
            : 'todo-app-todo-item default-item';
          return (
            <div
              className={className}
              onClick={() => handleItemClick(isFinish, todoItem.key)}>
              {isFinish ? (
                <CheckCircleOutlined />
              ) : (
                <div className={'todo-item-icon'} />
              )}
              <div className={'todo-item-value'}>{todoItem.value}</div>
            </div>
          );
        })}
      </div>

      <Space.Compact
        style={
          showAdd
            ? { width: '90%', marginLeft: '5%', marginRight: '5%' }
            : { display: 'none' }
        }>
        <Input
          ref={inputRef}
          placeholder='another input'
          value={inputValue}
          onChange={onChange}
        />
        <Button type='primary' onClick={addTodo}>
          Todo
        </Button>
      </Space.Compact>

      <PlusCircleOutlined
        className={'todo-add-btn'}
        style={{ fontSize: '4rem', color: '#3bb16c' }}
        onClick={clickAdd}
      />
    </div>
  );
};
export default TodoApp;
